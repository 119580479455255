import { createEnv } from "@t3-oss/env-nextjs"
import { env as envRuntime } from "next-runtime-env"
import { z } from "zod"

export const env = createEnv({
  server: {
    NODE_ENV: z.enum(["development", "production", "test"]).default("development"),
    GOOGLE_ANALYTICS_ID: z.string().optional(),
    SCREENSHOT_API_KEY: z.string(),
    GOOGLE_SITE_VERIFICATION_CONTENT: z.string().optional(),
  },
  client: {
    NEXT_PUBLIC_BASE_URL: z.string(),
    NEXT_PUBLIC_PLAYBOARD_API_URL: z.string(),
    NEXT_PUBLIC_LINE_LIFF_ID: z.string(),
    NEXT_PUBLIC_BYTEARK_STREAM_FORM_ID: z.string(),
    NEXT_PUBLIC_BYTEARK_STREAM_FORM_SECRET: z.string(),
    NEXT_PUBLIC_SUPABASE_URL: z.string(),
    NEXT_PUBLIC_SUPABASE_ANON_KEY: z.string(),
    NEXT_PUBLIC_BYTEARK_LIGHTHOUSE_PROJECT_ID: z.string(),
    NEXT_PUBLIC_POSTHOG_KEY: z.string().optional(),
    NEXT_PUBLIC_POSTHOG_UI_HOST: z.string().optional(),
    NEXT_PUBLIC_SENTRY_DSN: z.string().optional(),
    NEXT_PUBLIC_SENTRY_ENV: z.string().optional(),
    NEXT_PUBLIC_USER_SESSION_KEY: z.string(),
    NEXT_PUBLIC_MEMBER_SESSION_KEY: z.string(),
    NEXT_PUBLIC_SKIP_TERM_OF_SERVICE: z.string(),
    NEXT_PUBLIC_BYTEARK_PAY_URL: z.string().url(),
    NEXT_PUBLIC_DISPLAY_TOP_UP_BUTTON: z.string().optional(),
  },
  runtimeEnv: {
    NODE_ENV: process.env.NODE_ENV,
    GOOGLE_ANALYTICS_ID: process.env.GOOGLE_ANALYTICS_ID,
    SCREENSHOT_API_KEY: process.env.SCREENSHOT_API_KEY,
    GOOGLE_SITE_VERIFICATION_CONTENT: process.env.GOOGLE_SITE_VERIFICATION_CONTENT,

    NEXT_PUBLIC_SKIP_TERM_OF_SERVICE: envRuntime("NEXT_PUBLIC_SKIP_TERM_OF_SERVICE"),
    NEXT_PUBLIC_BASE_URL: envRuntime("NEXT_PUBLIC_BASE_URL"),
    NEXT_PUBLIC_PLAYBOARD_API_URL: envRuntime("NEXT_PUBLIC_PLAYBOARD_API_URL"),
    NEXT_PUBLIC_LINE_LIFF_ID: envRuntime("NEXT_PUBLIC_LINE_LIFF_ID"),
    NEXT_PUBLIC_BYTEARK_STREAM_FORM_ID: envRuntime("NEXT_PUBLIC_BYTEARK_STREAM_FORM_ID"),
    NEXT_PUBLIC_BYTEARK_STREAM_FORM_SECRET: envRuntime("NEXT_PUBLIC_BYTEARK_STREAM_FORM_SECRET"),
    NEXT_PUBLIC_SUPABASE_URL: envRuntime("NEXT_PUBLIC_SUPABASE_URL"),
    NEXT_PUBLIC_SUPABASE_ANON_KEY: envRuntime("NEXT_PUBLIC_SUPABASE_ANON_KEY"),
    NEXT_PUBLIC_BYTEARK_LIGHTHOUSE_PROJECT_ID: envRuntime("NEXT_PUBLIC_BYTEARK_LIGHTHOUSE_PROJECT_ID"),
    NEXT_PUBLIC_POSTHOG_KEY: envRuntime("NEXT_PUBLIC_POSTHOG_KEY"),
    NEXT_PUBLIC_POSTHOG_UI_HOST: envRuntime("NEXT_PUBLIC_POSTHOG_UI_HOST"),
    NEXT_PUBLIC_SENTRY_DSN: envRuntime("NEXT_PUBLIC_SENTRY_DSN"),
    NEXT_PUBLIC_SENTRY_ENV: envRuntime("NEXT_PUBLIC_SENTRY_ENV"),
    NEXT_PUBLIC_USER_SESSION_KEY: envRuntime("NEXT_PUBLIC_USER_SESSION_KEY"),
    NEXT_PUBLIC_MEMBER_SESSION_KEY: envRuntime("NEXT_PUBLIC_MEMBER_SESSION_KEY"),
    NEXT_PUBLIC_BYTEARK_PAY_URL: envRuntime("NEXT_PUBLIC_BYTEARK_PAY_URL"),
    NEXT_PUBLIC_DISPLAY_TOP_UP_BUTTON: envRuntime("NEXT_PUBLIC_DISPLAY_TOP_UP_BUTTON"),
  },
  skipValidation: true,
})
